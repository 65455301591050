import React from "react"
import BehaviorSectionImage from "../behaviorImage"

const BehaviorSection = () => {
  return (
    <section className="section behavior-section">
      <h1 className="main-heading" id="specialty-services">
        SPECIALTY SERVICES
      </h1>
      <div className="container is-fullhd">
        <div className="custom-columns">
          <div className="image-column">
            <BehaviorSectionImage />
          </div>
          <div className="content-column">
            <h3 className="column-heading">PennVet Behavior Portal</h3>
            <p>
              PennVet Behavior Web App is a teleconsulting service for
              veterinarians to access and communicate with PennVet behavior
              specialists and receive a consultation. The client facing side
              enables pet owners to upload videos and receive live-action video
              commentary by behaviorists. Veterinarians can access educational
              resources, curated by PennVet, and participate in a chat with
              experts to get their questions answered.{" "}
            </p>
            <p>
              Our pilot program was a success, stay tuned for our updated
              website and web app service! Contact{" "}
              <a href="mailto:hello@intellivets.com">hello@intellivets.com</a>{" "}
              for more information.{" "}
            </p>
            <a
              className="button link-button"
              href="https://pennvetbehavior.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Us
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BehaviorSection
