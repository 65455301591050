import React from "react"
import NutritionSectionImage from "../nutritionImage"

const NutritionSection = () => {
  return (
    <section className="section nutrition-section">
      <div className="container is-fullhd">
        <div className="custom-columns">
          <div className="content-column">
            <h3 className="column-heading">Veterinary Nutrition App</h3>
            <p>
              Our Veterinary Nutrition Web App enables veterinarians to gain
              access and receive nutrition consultations for their patients,
              including diet formulations from a boarded veterinary
              nutritionist. The client facing side enables pet owners to
              complete a detailed pet profile and questionnaire for the
              veterinary nutritionist. Veterinarians and pet owners can access
              veterinary nutritionist approved resources.
            </p>
            <a
              className="button link-button"
              href="https://app.intellivets.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Us
            </a>
          </div>
          <div className="image-column">
            <NutritionSectionImage />
          </div>
        </div>
      </div>
    </section>
  )
}

export default NutritionSection
