import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Intellivets from "../images/hero-logo.svg"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HomeHeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      homeHeroImageDesktop: file(relativePath: { eq: "home-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeHeroImageMobile: file(relativePath: { eq: "home-bg-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="hero-section container is-fullhd">
      <div className="container is-max-widescreen">
        <div className="hero-content">
          <img className="hero-logo" src={Intellivets} alt="intellivets" />
          <h1>Veterinary Telehealth for Specialized Pet Care</h1>
          <p>
            Intellivets empowers veterinarians and pet owners with access to
            veterinary specialty services through telehealth capabilities.{" "}
          </p>
          <a
            className="button link-button"
            href="https://app.intellivets.com/signup"
          >
            Sign up
          </a>
        </div>
      </div>
      <Img
        className="hero-image is-hidden-mobile"
        fluid={data.homeHeroImageDesktop.childImageSharp.fluid}
        alt="hero image Intellivets"
      />
      <Img
        className="hero-image is-hidden-tablet"
        fluid={data.homeHeroImageMobile.childImageSharp.fluid}
        alt="hero image Intellivets"
      />
    </div>
  )
}

export default HomeHeroImage
