import React from "react"
// import ProfileImage from "../../images/avatar.svg"
import KimmiProfile from "../../images/whitehead.png"
import jackieProfile from "../../images/jackie-profile.png"
// import MarthaClineProfile from "../../images/martha_cline.png"

const LeadershipSection = () => {
  return (
    <section className="section-leadership">
      <div className="container is-fullhd">
        <h3>Leadership</h3>
        <div className="columns leadership-columns">
          <div className="column">
            <div className="image-holder">
              <img src={KimmiProfile} alt="Dr. KimMi Whitehead" />
              <p>Dr. KimMi Whitehead, VMD, DACVECC, CEO</p>
            </div>
          </div>
          <div className="column is-three-quarters leadership-content">
            <p>
              Dr. KimMi Whitehead, VMD, DACVECC is the chief executive officer
              of Intellivets, and is a Diplomate of the American College of
              Veterinary Emergency and Critical Care (DACVECC). KimMi’s special
              areas of interest include veterinary telehealth, gastrointestinal
              dysmotility in critical care and polytrauma. She is also the
              co-founder of a veterinary online education and training company,
              Intensivets, LLC.{" "}
            </p>
            <p>
              She completed her undergraduate studies at Wellesley College
              before beginning graduate school at the University of Pennsylvania
              School of Veterinary Medicine, where she earned her veterinary
              degree. After completing a one-year internship at Animal Surgical
              Emergency Center in Los Angeles, California, KimMi entered a
              three-year residency in Emergency and Critical Care Medicine at
              Oradell Animal Hospital, a specialty/emergency referral hospital
              in Paramus, New Jersey. She then helped establish the critical
              care department at the Veterinary Specialty Center of Delaware.{" "}
            </p>
            <p>
              KimMi grew up in Seoul, Korea and speaks Korean, English and
              beginner French. She lives in Philadelphia with her husband,
              daughter, two fluffy dogs, Penny Lane and Eleanor Rigby, and one
              very bossy cat, Sookie.
            </p>
          </div>
        </div>
        <div className="columns leadership-columns ">
          <div className="column">
            <div className="image-holder">
              <img src={jackieProfile} alt="Jacqueline Cooper, COO" />
              <p>Jacqueline Cooper, MBA, COO</p>
            </div>
          </div>
          <div className="column is-three-quarters leadership-content">
            <p>
              As chief operating officer of Intellivets, Jacqueline Cooper is
              responsible for the operations of the company. Her career
              experience and leadership have been focused on developing and
              delivering growth-driven business services and solutions,
              providing outstanding client service, and driving profitable
              revenue growth for startup companies.{" "}
            </p>
            <p>
              In 2016, Jacqueline founded Cardona Consulting serving as
              President and Consultant, providing growth-driven business and
              marketing services. Previously, Jacqueline served as Head of
              Marketing for a successful startup spearheading business
              development, demand generation, and marketing strategies
              contributing to the company’s sale.
            </p>
            <p>
              Jacqueline has a Bachelor of Science degree in Marketing
              Management and received her Executive Master of Business
              Administration from Smeal College of Business at Penn State
              University. She serves in board of director and volunteer roles at
              American Marketing Association, PAWS, ASPCA, and Broad Street
              Homeless Outreach Services. Jacqueline lives in Philadelphia with
              her family and beloved Norwegian Forest cat, Rio.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LeadershipSection
