import React from "react"

const VideoSection = () => {
  return (
    <section className="section video-section">
      <div className="container is-fullhd">
        <video height="auto" controls>
          <source
            src="https://intellivets-website.s3.amazonaws.com/intellivets-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  )
}

export default VideoSection
