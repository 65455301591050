import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import SeoImage from "../images/home-bg.png"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import HomeHeroImage from "../components/heroImage"
// sections
import BehaviorSection from "../components/homePage/behaviorSection"
import NutritionSection from "../components/homePage/nutritionSection"
import CompanySection from "../components/homePage/companySection"
import LeadershipSection from "../components/homePage/leadershipSection"
import VideoSection from "../components/homePage/videoSection"

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      {/* <Seo
        title="Home"
        description="Intellivets was founded with the goal of empowering veterinarians and pet parents by providing access to specialized pet care through telehealth capabilities."
      /> */}
      <GatsbySeo
        title="Home | Intellivets"
        description="Intellivets was founded with the goal of empowering veterinarians and pet parents by providing access to specialized pet care through telehealth capabilities."
        canonical="https://intellivets.com"
        openGraph={{
          url: "https://intellivets.com",
          title: "Home | Intellivets",
          description:
            "Intellivets was founded with the goal of empowering veterinarians and pet parents by providing access to specialized pet care through telehealth capabilities.",
          images: [
            {
              url: SeoImage,
              width: 800,
              height: 600,
              alt: "Intellivets",
            },
            // {
            //   url: "https://www.example.ie/og-image-02.jpg",
            //   width: 900,
            //   height: 800,
            //   alt: "Og Image Alt Second",
            // },
            // { url: "https://www.example.ie/og-image-03.jpg" },
            // { url: "https://www.example.ie/og-image-04.jpg" },
          ],
          site_name: "Intellivets",
        }}
      />
      <HomeHeroImage />
      <VideoSection />
      <BehaviorSection />
      <NutritionSection />
      <CompanySection />
      <LeadershipSection />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
