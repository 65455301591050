import React from "react"

const CompanySection = () => {
  return (
    <section className="section-company">
      <div className="container is-fullhd">
        <h3 id="our-company">Our Mission</h3>
        <p>
          Intellivets was founded with the goal of empowering veterinarians and
          pet parents by providing access to specialized pet care through
          telehealth capabilities.
        </p>
      </div>
    </section>
  )
}

export default CompanySection
